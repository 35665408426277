@import '../../pagesMixins.scss';

.productList {
  margin: 0px 5%;

  &_addNew_button {
    margin: 20px 0px;
  }

  &_buttonRow {
    display: flex;
    justify-content: center;
  }
}

.buttons {
  color: $relayfy-white !important;
}

.form {
  margin: 5%;

  &_footer_buttons {
    text-align: center;
    // background-color: white;
    width: 100%;
    padding: 8px 12px;
    border-top: 1px solid black;
    border-top-width: thin;
    box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.43);
    font-weight: 700;

    @include breakpoint(small) {
      margin: 0 auto;
    }

    & button {
      margin: 0px 10px;
    }

    &_cancel {
      background-color: $relayfy-grey !important;
      color: $relayfy-white !important;
    }

    &_save {
      @extend .buttons;
    }

    &_add {
      @extend .buttons;
    }

    &_delete {
      @extend .buttons;
      background-color: #F84E60 !important;
    }

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.card {

  &_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: right;

    &_editIcon {
      width: 45px;
    }

    &_addIcon {
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
}