@import '../../components/UI/mixin.scss';

$blue: #65C3EA;
$white: white;

$gapSpace: 18px;

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;

  .bizInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    font-size: 18px;
    font-weight: 900;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: $gapSpace;

    .button {
      color: white;
      height: 44px;
      width: 40%;
      
      
      @include breakpoint(small) {
        width: 90%;
      }
    }
  }

}