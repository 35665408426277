@import "../pagesMixins.scss";

.configPageBase {
  margin: 24px;
  padding-bottom: 64px;
  height: auto;

  .table {
    margin-bottom: 32px;
  }
}

.businessInfoEdit {
  @extend .configPageBase;
}

.workingHours {
  @extend .configPageBase;
}

.orderingEdit {
  @extend .configPageBase;
}

.promotionAndCharges {
  @extend .configPageBase;
}

.header {
  &_title {
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    min-height: 1rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.28571429em;
    margin: 0px 40px;
  }

  &_buttons {
  }
}

.labelBase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: keep-all;
}

.contentWrapBase {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 600px;
  gap: 20px;
}

.contentBase {
  display: flex;
  // width: 200px;
  gap: 20px;
}

.rowBase {
  margin: 2em 0px;
  display: grid !important;
  grid-template-columns: 1fr 5fr;
}

.body {
  &_workingHours {
    @extend .body_row;

    .rows {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_label {
      display: flex;
      flex-direction: column;
      word-wrap: normal;
    }

    &_row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-auto-flow: column;
      max-width: 600px;
      gap: 2em;

      &_label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .addWorkingHourBtn {
          width: 176px;
        }
      }

      .hoursContainer {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        max-width: 600px;
        gap: 8px;

        .hourRow {
          display: flex;
          flex-direction: row;
          gap: 2em;

          >div {
            min-width: 72px;
          }
        }

      }

      .closedCheckbox {
        align-items: start;

        > span:nth-child(2) {
          margin-top: 2.5px;
        }
      }
    }
  }

  &_discount {
    &_deleteButton {
      opacity: 0.8;
      background-color: #f84e60 !important;
      color: $relayfy-white !important;

      &:hover {
        opacity: 1;
      }
    }
  }

  .multiInputRow {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    width: 50vw;
    margin: 0px 40px;

    @include breakpoint(medium) {
      width: 80vw;
    }

    @include breakpoint(small) {
      width: 100vw;
    }
  }

  &_row {
    @extend .rowBase;

    .color {
      width: 100%;
      height: 14px;
      border-radius: 2px;
    }

    &_swatch {
      padding: 5px;
      background: #fff;
      border-radius: 1px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      display: inline-block;
      cursor: pointer;
    }

    &_popover {
      position: absolute;
      z-index: 2;
    }

    &_cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    .inner_row {
      margin-top: 6px;

      &_label {
        width: 50%;
        text-align: center;
      }

      &_content {
        display: flex;
        align-items: center;
      }
    }

    .label {
      @extend .labelBase;
    }

    .contentWrap {
      @extend .contentWrapBase;

      .content {
        @extend .contentBase;

        > div {
          width: 300px;
        }

        .dollarSignWrap {
          display: flex;

          .dollarSign {
            display: inline-block;
            padding-top: 4px;
          }

          > div {
            width: 100%;
          }
        }

        textarea {
          border: none;
          border-bottom: 1px solid #999;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          min-width: 300px;
        }
      }

      .helperText {
      }
    }
  }
}

.labelsAutocomplete {
  width: 100%;
}

.multiRow {
  @extend .rowBase;

  .label {
    @extend .labelBase;
  }

  .contentWrap {
    @extend .contentWrapBase;

    .tipsInputs {
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      * {
        font-family: Quicksand, sans-serif;
        width: 60px;
        font-size: 16px;
      }
    }

    .content {
      @extend .contentBase;
      max-width: 600px;

      div {
        width: 300px;
      }

      input {
        margin-right: 24px;
        min-width: unset !important;
      }
    }

    .helperText {
    }
  }
}

.errorIndicator {
  color: red;
}
