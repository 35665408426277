@import '../UI/mixin.scss';

.sideNav {
  background-color: $relayfy-lightgrey !important;


  .sideList {
    height: 100%;
    overflow-y: auto;
  }

  .linksList {
    height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &_accordionWrap {
    margin: unset !important;
  }

  &_paper {
    max-width: 150px;
  }

  &_storeTitle {
    margin: 16px;
    font-family: $quickSand;
    font-weight: 800;
  }

  &_accordion {
    display: flex;
    flex-direction: column;
    padding: unset !important;
    padding-left: 16px !important;
  }

  &_item {
    color: grey !important;
    display: flex;
    align-items: center;
  }

  .footer {
    position: absolute !important;
    bottom: 0;
    background-color: $relayfy-white;

    >a {
      color: $relayfy-black;
    }

    .item {
      display: flex;
      justify-content: space-between !important;

      &:hover {
        color: #1e70bf;
        cursor: pointer;
      }
    }
  }
}

.navLink {
  width: 100%;

  &_icon {
    display: inline-block;
    min-width: 26px;
    text-align: center;
  }

  &_accordionContent {
    margin-left: 14px;
    padding-top: 4px;

  }

  &_content {
    margin-left: 10px;
  }
}

.accordion {
  &_dropdown {
    margin-left: 14px;
    padding-top: 4px;
  }

  &_subLink {
    width: 100%;
    box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.2);
    // box-shadow: 5px 2px #888888;
  }
}