@import "../UI/mixin.scss";

.popup {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  outline: none;
  border: 1px solid;
  max-width: 450px;
  max-height: 800px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto !important;
  border-radius: 15px;
  overflow: hidden;

  &_container {
    z-index: 2;
    // display: inline-block;
  }

  @include breakpoint(small) {
    // width: 100vw;
  }

}

.body {
  min-width: 280px;

  &_content {
    text-align: center;
    height: auto;
    padding: 20px 20px 0px;
  }

  &_footer {
    min-height: 40px;
    width: 100%;
    text-align: center;
    // padding-bottom: 10px;

  }

  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_label {
      display: flex;
      align-items: center;
      font-size: 22px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 50px;
      padding: 10px 20px;
      font-weight: bold;
    }

    &_closeButton {
      font-size: 24px;
      padding: 10px 20px;
    }
  }
}

.okayFooter {
  width: 100%;

  &_separator {
    margin-bottom: 0;
  }

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }
}

.okCancelFooter {

  display: flex;
  justify-content: space-around;
  height: 100%;

  &>* {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
    &:hover{
      // cursor: pointer;
      background-color: $relayfy-grey;
    }
    &:disabled {
      cursor: not-allowed;
      pointer-events: all !important;
    }
  }

  &_okayButton {}

  &_cancelButton {}

}