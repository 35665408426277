@import '../../components/UI//mixin.scss';

.errorPage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  color: white;
  background: linear-gradient(#fbb44c, #e75823) fixed;
  
  & div>* {
    padding: 0px;
    margin: 0px;
    font-family: $quickSand;
    font-size: 16px;
  }

  & div>h2,
  div>h1 {
    font-family: $quickSand;
  }

  & div>h2 {
    font-size: 80px;
  }

  & div>h1 {
    font-size: 32px;
    padding-bottom: 20px;
  }
}