@import '../UI/mixin.scss';

.form {

  &_row {
    display: grid !important;
    grid-template-columns: 33% 77%;
    text-align: left;
    margin: 10px 0px;

    &_label {
      display: flex;
      align-items: center;
    }

    &_inputWrap {
      flex-grow: 100;
      margin-left: 0.8em;
      padding-bottom: 4px;
      max-height: unset !important;
    }

    &_labelClose {
      @extend .form_row_label;
      width: 16%;
    }

    &_inputWrapClose {
      @extend .form_row_inputWrap;
      margin-left: unset;
    }

    &_input {
      // flex-grow: 100;
      border: none;
      border-bottom: 1px solid #999;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
    }

    &_numberInput {
      @extend .form_row_input;
      flex-grow: 0;
      max-width: 80px;
    }

    &_textField {

      @include breakpoint(small) {
        font-size: 14px;
      }
    }

    &_textArea {
      @extend .form_row_input;
      max-height: 160px;
      max-width: 220px;
    }

    &_radio {
      max-width: 200px;
    }
  }
}