.MuiButtonBase-root {
  padding: 5px;
}

.MuiSvgIcon-fontSizeSmall {
  max-width: 18px;
}

// .MuiFormGroup-row {
//   >div {
//     max-height: 50px;
//   }
// }