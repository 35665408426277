.imgCard {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: right;

  .container {
    width: 80px;
    height: 80px;
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 25%;
  }

  .editIcon {
    position: absolute !important;
    width: 45px;
    top: 56px;
    right: 30px;
  }

}