.row {
  display: flex;
  gap: 12px;
  padding-bottom: 4px;

  .label {
    display: flex;
    align-items: center;
    width: 33%;
  }

  .inputWrap {
    display: flex;
    justify-content: start;
    flex-grow: 100;
    
    .muiInput {
      width: 100%;
    }
  }
}