$relayfy-lightgrey: #EBEBEB;

.container {
  height: 100%;
  width: 100%;
}

.newBusiness {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  margin-left: 150px;
}