@import '../UI/mixin.scss';


.breadcrumb {
  &_button {
    width: inherit;
    font-size: 1rem;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: rgba(0, 0, 0, .6) !important;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
    -webkit-user-select: none;
    user-select: none;
    will-change: '';
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background-color: #cacbcd;
      background-image: none;
      color: rgba(0, 0, 0, .8);
    }
  }

  &_link {
    &_active {
      font-weight: "bold";
      color: "black";
    }
  }
}