.dashboardPage {
  overflow-y: auto;
  margin: 1rem;
  max-width: 1290px;
}

.headers {
  margin-top: 20px !important;
}

.h1 {
  margin-bottom: 40px !important;
  @extend .headers;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.28571429em;
  margin: calc(2rem - .14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
  min-height: 1rem;
  font-size: 2rem;
}

.h2 {
  @extend .headers;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.28571429em;
  margin: calc(2rem - .14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
  font-size: 1.71428571rem;
}

.row {
  display: grid;
  grid-template-columns: repeat(6, minmax(160px, 1fr));
  overflow-x: auto;
  width: 100%;
  margin-bottom: 1rem;

  &_item {
    margin-right: 2em;

    &_label {
      min-width: 70px;
      max-width: 140px;
      // font-weight: 900;
      // opacity: .8;
    }

    &_data {
      min-width: 40px;
      max-width: 100px;
      font-weight: 900;
      // opacity: .8;
    }

    &>* {
      display: inline-block;
      word-break: keep-all;
      word-wrap: normal;
      margin-right: 1em;
    }
  }
}