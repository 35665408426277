@import '../UI/mixin.scss';

.buttons {
  color: $relayfy-white !important;
}

.form {
  margin: 5%;

  &_footer_buttons {
    text-align: center;
    width: 100%;
    padding: 8px 12px;
    border-top: 1px solid black;
    border-top-width: thin;
    box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.43);
    font-weight: 700;

    @include breakpoint(small) {
      margin: 0 auto;
    }

    & button {
      margin: 0px 10px;
    }

    &_cancel {
      background-color: $relayfy-grey !important;
      color: $relayfy-white !important;
    }

    &_save {
      @extend .buttons;
    }

    &_add {
      @extend .buttons;
    }

    &_delete {
      @extend .buttons;
      background-color: #F84E60 !important;
    }

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}