@import "../../pagesMixins.scss";

.optionList {
  margin: 0px 5%;

  &_title {
    width: 400px;

    .section {
      padding: 1em;
      background-color: $relayfy-lightgrey;
    }
  }

  &_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > * {
      margin-bottom: 10px !important;
    }
  }

  &_addNew_button {
    margin: 20px 0px;
  }

  &_aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: right;

    &_featured {
      margin-top: 10px;
    }
  }

  &_section {
    &_actions {
      display: flex;
      justify-content: space-between;
    }
  }

  .originalPrice {
  }

  .discountedPrice {
    color: #087813;
    margin-left: 8px;
  }
}

.table {
  &_container {
    padding: 2em;
  }
}

.card {
  &_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: right;

    &_editIcon {
      width: 45px;
    }

    &_addIcon {
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
}

.newSection {
  .minMaxConfig {
    display: flex;
    flex-direction: column;
    align-items: start;

    >* {
      padding-left: 16%;
    }
  }
}

.customize {
  &_row {
    background-color: $relayfy-lightgrey;

    &_nameValue {
      & > * {
        // margin-right: 10px;
      }
    }
  }

  &_anyOfTables {
    background-color: #cccfff !important;
    margin: 2em;
  }

  &_oneOfTables {
    background-color: #99ccff !important;
  }

  &_paper {
    & > * {
      margin: 2em;
    }
  }

  &_deleteButton {
    opacity: 0.8;
    background-color: #f84e60 !important;
    color: $relayfy-white !important;

    &:hover {
      opacity: 1;
    }
  }

  &_footer {
    padding: 20px 20px;
    display: flex;
    justify-content: center;

    & > button {
      background-color: $relayfy-darkblue !important;
      padding: 2em !important;
    }
  }
}
