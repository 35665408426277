@import '../../components/UI/mixin.scss';

$pagePadding: 2em;

.account {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(0deg, #e6511b 0%, #feb74d 100%);
  color: $relayfy-white;
  padding-left: $pagePadding;
  padding-right: $pagePadding;
}

.content {
  width: 100%;
  max-width: 320px;
  padding-top: 6em;
  font-family: $quickSand;
}