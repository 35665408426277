.imgUpload {
  &_form {
    text-align: center;

    &>img {
      margin-bottom: 1em;
    }
  }
}

.card {

  &_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: right;

    &_editIcon {
      width: 45px;
    }

    &_addIcon {
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
}

.error {
  padding-top: 10%;
}