.globalModal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.paper {
  background-color: #fff;
  max-height: 80vh;
  max-width: 450px;
  width: 80%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    display: inline-block;
    font-size: 18px;
    margin: 0 0;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    color: #fff;
  }

  &_header {
    display: flex;
    justify-content: space-between;
  }

  &_closeIcon {
    display: flex;
    align-items: center;
    padding: 10px;
    color: white;
  }

  &_content {
    text-align: center;
    padding: 20px;
    overflow: auto;
    max-height: 460px;
  }

  &_buttonGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    border-top: 1px solid #e1e1e1;
    height: 50px;
    margin: 0 0;

    button {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      font-size: 14px;
      font-family: Quicksand, sans-serif;
      font-weight: 800;
      font-size: 14px;

      &:first-child {
        border-right: 1px solid #e1e1e1;
      }

    }
  }

  &_footer {
    z-index: 10;
    background-color: white;
  }

  &_cancel {
    color: #999;
  }

  &_ok {
    color: #fea833;
  }
}